
import React, { createContext, useState } from "react";

export const QtdContext = createContext();

const QtdProvider = ({ children }) => {
  const [selectedService, setSelectService] = useState('');

  return (
    <QtdContext.Provider value={{ selectedService, setSelectService }}>
      {children}
    </QtdContext.Provider>
  );
};

export default QtdProvider;

