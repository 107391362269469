import React, { useState } from 'react'
import './Home.css'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-scroll'
import Particles from 'react-particles-js'
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle'
import Navbar from '../navbar/Navbar'
import config from '../../config/index'
import configM from '../../config/index2'
import configS from '../../config/index3'
import julian from '../../images/julian.png'

const Home = () => {
  const [imageLoaded, setImageLoaded] = useState(false)
  // console.log('window.innerWidth',window.innerWidth)
  return (
    <div className="home-wrapper">
      <div className="home">
        <Particles
          className="particles"
          params={window.innerWidth>1100?config.particles:window.innerWidth>780?configM.particles:configS.particles}
        />
        <div className={`greeting${!imageLoaded ? ' hide' : ''}`}>
          <Fade bottom distance="40px">
            <img
              className="julian"
              alt="julian"
              src={julian}
              onLoad={() => setImageLoaded(true)}
            />
            <h1 className="greeting-text">
               <span className="name">Добро пожаловать</span>{' '}
              <span className="wave-emoji" role="img" aria-label="waving hand">👋</span>
            </h1>
            <h1 className="greeting-text">Мы современная WEB Студия.</h1>
            <div className="scroll-down">
              <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-63}
                duration={500}
              >
                <ArrowDropDownCircleIcon
                  fontSize="large"
                  style={{ pointerEvents: 'fill', cursor: 'pointer' }}
                />
              </Link>
            </div>
          </Fade>
        </div>
        <Navbar />
      </div>
    </div>
  )
}

export default Home
