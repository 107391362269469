import React from 'react'
import './About.css'
import Typewriter from 'typewriter-effect'
import { Link } from 'react-scroll'
import { Fade } from 'react-reveal'
import Section from '../section/Section'
import Skills from '../skills/Skills'

const About = () => {
  return (
    <Section title="About" name="О нас">
      <div className="about-content">
        <Fade duration={1000}>
          <div className="about-text">
            <h2>Кто мы?</h2>
            <p>
              Мы WEB студия Qrees{' '}
              <span role="img" aria-label="lightning">
                😄
              </span>{' '}
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
              Мы команда профессиональных дизайнеров, инженеров и разработчиков. Мы беремся за проекты любой сложности. От простых скриптов до сложных многоуровневых систем.
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
              Мы работаем с JavaScript, TypeScript, React, NodeJS, Nginx, Linux, Docker, HTML/CSS.
            </p>
            <div className="typewriter">
              <p className="typewriter-start">
                <span role="img" aria-label="lightning">
                  ⚡ Мы умеем
                </span>{' '}
                <div style={{display:'initial'}}>
                  <Typewriter
           
                    options={{
                      strings: [
                        'писать скрипты.',
                        'делать дизайны сайтов и верстать их.',
                        'создавать лендинги для вас и ваших компаний.',
                        'творчески подходить к работе.',
                        'докерезировать недокерезуемое.',
                        'создавать роботов для telegram.',
                        'находить и исправлять баги.',
                        'администрировать сервера.',
                        'настраивать домены.',
                        'разрабатывать сложные backens приложения.',
                        'работать с базами данных.',
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </div>
              </p>

              {/* <p>.</p> */}
            </div>
            <p>
              Мы всегда с полной ответственностью подходим к своим проектам, уделяем внимание каждой детали и пожеланию. По этому мы на рынке уже 3 года.
              {/* Вы можете взглянуть на некоторые наши {' '}
              <Link
                className="textLink"
                to="projects"
                spy={true}
                smooth={true}
                duration={500}
              >
                проекты
              </Link> */}
              {' '}Так же у нас есть комплексные и индивидуальные{' '}
              <Link
                className="textLink"
                to="experience"
                spy={true}
                smooth={true}
                duration={500}
              >
                услуги
              </Link>
              . Мы реализуем вашу задумку в цифровое чудо.
            </p>
            {/* <div className="location-wrapper">
              <svg
                className="octicon octicon-location"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                height="16"
                aria-hidden="true"
              >
                <path
                  fill="white"
                  fillRule="evenodd"
                  d="M11.536 3.464a5 5 0 010 7.072L8 14.07l-3.536-3.535a5 5 0 117.072-7.072v.001zm1.06 8.132a6.5 6.5 0 10-9.192 0l3.535 3.536a1.5 1.5 0 002.122 0l3.535-3.536zM8 9a2 2 0 100-4 2 2 0 000 4z"
                ></path>
              </svg>
              <p>Sydney, Australia</p>
            </div> */}
          </div>
        </Fade>
        <Skills />
      </div>
    </Section>
  )
}

export default About
