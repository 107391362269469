// module.exports = {
//   particles: {
//     particles: {
//       number: { value: 200 },
//       color: { value: ['#cc892b', '#1cd6b1', '#8c4ec7', '#8bc74e'] },
//       opacity: {
//         value: 0.5,
//         random: false,
//         anim: {
//           enable: true,
//           speed: 0.2,
//           opacity_min: 0.3,
//           sync: false,
//         },
//       },

//       size: {
//         value: 5,
//         random: true,
//       },
//       line_linked: {
//         enable: true,
//         distance: 150,
//         color: '#ffffff',
//         opacity: 0.1,
//         width: 1,
//       },
//       move: {
//         enable: true,
//         speed: 1,
//         direction: 'none',
//         random: true,
//         straight: false,
//         bounce: true,
//       },
//     },
//     interactivity: {
//       detect_on: 'canvas',
//       // activate
//       events: {
//         onhover: {
//           enable: true,
//           mode: ['bubble'],
//         },
//         resize: true,
//       },
//       modes: {
//         bubble: {
//           distance: 200,
//           size: 12,
//           duration: 1,
//           opacity: 0.8,
//           speed: 2,
//         },
//       },
//     },
//     retina_detect: true,
//   },
// }

// module.exports = {
//   particles: {
//     particles: {
//       number: { value: 30 },
//       color: { value: ['#cc892b', '#1cd6b1', '#8c4ec7', '#8bc74e'] },
//       opacity: {
//         value: 0.5,
//         random: false,
//         anim: {
//           enable: true,
//           speed: 0.2,
//           opacity_min: 0.3,
//           sync: false,
//         },
//       },
//       size: {
//         value: 15,
//         anim: {
//           enable: true, // Анимация размера
//           speed: 2,     // Скорость изменения размера
//           size_min: 10, // Минимальный размер
//           sync: false,  // Асинхронное изменение размера
//         },
//         random: true,
//       },
  
//       shape: {
//         type: 'images',
//         image: [
//           { src: './react.png', width: 32, height: 32 },
//           { src: './node.png', width: 32, height: 32 },
//           { src: './web.png', width: 32, height: 32 },
//           { src: './css.png', width: 32, height: 32 },
//           { src: './html.png', width: 32, height: 32 },
//           { src: './db.png', width: 32, height: 32 },
//           { src: './redis.png', width: 32, height: 32 },
//           { src: './docker.png', width: 32, height: 32 },
//         ],
//       },
//       line_linked: {
//         enable: true,
//         distance: 150,
//         color: '#ffffff',
//         opacity: 0.1,
//         width: 1,
//       },
//       move: {
//         enable: true,
//         speed: 1,
//         direction: 'none',
//         random: true,
//         straight: false,
//         bounce: true,
//       },
//     },
//     interactivity: {
//       detect_on: 'canvas',
//       events: {
//         onhover: {
//           enable: true,
//           mode: ['bubble'],
//         },
//         resize: true,
//       },
//       modes: {
//         bubble: {
//           distance: 200,
//           size: 25,
//           duration: 1,
//           opacity: 0.8,
//           speed: 2,
//         },
//       },
//     },
//     retina_detect: true,
//   },
// }
module.exports = {
  particles: {
    particles: {
      number: { value: 20 },
      color: { value: ['#cc892b', '#1cd6b1', '#8c4ec7', '#8bc74e'] },
      opacity: {
        value: 0.8,
        random: false,
        anim: {
                    enable: true,
                    speed: 0.2,
                    opacity_min: 0.3,
                    sync: false,
                  },
      },
      size: {
        value: 15,
       random: false,
       anim: {
           enable: true,
           speed: 4,
           size_min: 15,
           sync: false
        }
    },
      shape: {
        type:   "images",
        image: [
          { src: './react.png', width: 24, height: 24 },
          { src: './node.png', width: 24, height: 24 },
          { src: './web.png', width: 24, height: 24 },
          { src: './css.png', width: 24, height: 24 },
          { src: './html.png', width: 24, height: 24 },
          { src: './db.png', width: 24, height: 24 },
          { src: './redis.png', width: 24, height: 24 },
          { src: './docker.png', width: 24, height: 24 },
          { src: './k8s.png', width: 24, height: 24 },
          { src: './debian.png', width: 24, height: 24 },
          { src: './tg.png', width: 24, height: 24 },
        ],
      },
      line_linked: {
        enable: true,
        distance: 130,
        color: '#ffffff',
        opacity: 0.3,
        width: 2,
      },
      move: {
        enable: true,
        speed: 2, // Ограничиваем скорость частиц
        direction: 'none',
        random: true,
        straight: false,
        bounce: true, // Отключаем отскоки
      },
    },
    interactivity: {
      detect_on: 'canvas',
      events: {
        onhover: {
          enable: true,
          mode: ['repulse'], // Эффект увеличения при наведении
        },
        resize: true,
      },
      modes: {
        bubble: {
          distance: 150,
          size: 42, // Максимальный размер иконки при наведении
          duration: 1,
          opacity: 0.9,
          speed: 2,
        },
      },
    },
    retina_detect: true,
  },
};
