import React from 'react'
import './App.css'
import Home from './components/home/Home'
import About from './components/about/About'
import Experience from './components/experience/Experience'
// import Projects from './components/projects/Projects'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import TopButton from './components/topButton/TopButton'
import QtdProvider from "./context/qtdContext";

function App() {
  return (

    <div className="App">
      <QtdProvider
      // setLoading={setLoading}
      >
        <Home />
        <About />
        <Experience />
        {/* <Projects /> */}
        <Contact />
        <Footer />
        <TopButton />
      </QtdProvider>

    </div>
  )
}

export default App
