import React, { useState, useContext } from 'react'
import './ContactForm.css'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import ListSubheader from '@material-ui/core/ListSubheader'
import { QtdContext } from "../../context/qtdContext";
import axios from 'axios'
const useStyles = makeStyles((theme) => ({
  select: {
    '& > *': {
      marginBottom: theme.spacing(2),
      backgroundColor: '#3b4353',
      color: 'white',
      '&:hover': {
        backgroundColor: '#3b4353',
        color: 'white',
      },
      '& .Mui-focused': {
        backgroundColor: '#3b4353',
        color: 'white',
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: '2px solid #6f7b9b',
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: '2px solid #258b9e',
      },
    },
  },
  email: {
    '& > *': {
      marginBottom: theme.spacing(2),
      backgroundColor: '#3b4353',
      color: 'white',
      '&:hover': {
        backgroundColor: '#3b4353',
        color: 'white',
      },
      '&.Mui-focused': {
        backgroundColor: '#3b4353',
        color: 'white',
      },
      '&.MuiFilledInput-underline:before': {
        borderBottom: '2px solid #6f7b9b',
      },
      '&.MuiFilledInput-underline:after': {
        borderBottom: '2px solid #258b9e',
      },
    },
  },
  message: {
    '& > *': {
      marginBottom: theme.spacing(2),
      backgroundColor: '#3b4353',
      color: 'white',
      '&:hover': {
        backgroundColor: '#3b4353',
        color: 'white',
      },
      '&.Mui-focused': {
        backgroundColor: '#3b4353',
        color: 'white',
      },
      '&.MuiFilledInput-underline:before': {
        borderBottom: '2px solid #6f7b9b',
      },
      '&.MuiFilledInput-underline:after': {
        borderBottom: '2px solid #258b9e',
      },
    },
  },
  submit: {
    '&': {
      backgroundColor: '#258b9e',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#2999ae',
        boxShadow: 'none',
      },
    },
    '& > *': {
      color: 'white',
      fontSize: '15px',
      fontWeight: '600',
    },
  },
}))

const ContactForm = () => {
  const { selectedService, setSelectService } = useContext(QtdContext);
  console.log(selectedService)
  const [err, setErr] = useState({
    selectedService: false,
    emailText: false,
    phone: false,
    name: false,
    tg_name: false,
    messageText: false,
    selectedCell: false,
  })
  const [status, setStatus] = useState('')
  const [emailText, setEmailText] = useState('')
  const [phone, setphone] = useState('')
  const [name, setName] = useState('')
  const [tg_name, settg_name] = useState('')
  const [messageText, setMessageText] = useState('')
  const [selectedCell, setSelectCell] = useState('')
  const handlePhoneChange = (event) => {
    const input = String(event.target.value)
    setphone(input)
  }
  const handleTgChange = (event) => {
    const input = String(event.target.value)
    settg_name(input)
  }
  const handleSelectChange = (event) => {
    setSelectService(event.target.value)
  }
  const handleSelectChangeCall = (event) => {
    setSelectCell(event.target.value)
  }

  const classes = useStyles()

  const submitForm = async (ev) => {
    ev.preventDefault()

    console.log(emailText, phone, tg_name, messageText, selectedService)
    let t = {
      selectedService: false,
      emailText: false,
      phone: false,
      name: false,
      tg_name: false,
      messageText: false,
      selectedCell: false,
    }
    if (!emailText) {
      t.emailText = true
    }
    if (!messageText) {
      t.messageText = true
    }
    if (selectedCell === "Звонок" || selectedCell === "WhatsApp") {
      if (!phone) {
        t.phone = true
      }
    }
    if (!selectedService) {
      t.selectedService = true
    }

    if (!name) {
      t.name = true
    }

    if (selectedCell === "Telegram") {
      // if (!tg_name) {
      //   t.tg_name = true
      // }
      if (!phone) {
        t.phone = true
      }
    }
    if (!selectedService) {
      t.selectedService = true
    }
    if (!selectedCell) {
      t.selectedCell = true
    }
    setErr(t)
    console.log(t)
    if (
      !t.emailText &&
      !t.selectedService &&
      !t.phone &&
      !t.name &&
      !t.tg_name &&
      !t.messageText &&
      !t.selectedCell
    ) {


       axios.post('https://api.kotopes39.ru/api/qrees/order', {
        selectedService: selectedService,
        emailText: emailText,
        name: name,
        phone: phone,
        tg_name: tg_name,
        messageText: messageText,
        selectedCell: selectedCell
      })

      setStatus('SUCCESS')
    }

  }

  const handleEmailChange = (event) => {
    const input = String(event.target.value)
    setEmailText(input)
  }
  const handleNameChange = (event) => {
    const input = String(event.target.value)
    setName(input)
  }

  const handleMessageChange = (event) => {
    const input = String(event.target.value)
    setMessageText(input)
  }

  return (
    <div className="contact-form-wrapper">
      <form
        className="contact-form"
        onSubmit={submitForm}
        // action="https://formspree.io/xayplakr"
        // method="POST"
      >


        {status === 'SUCCESS' ? (
          <p className="email-success">Спасибо за вашу заявку. в скором времени наш менеджер с вами свяжется для уточнения подробностей</p>
        ) : (<>

          {/* <FormControl className={classes.select} variant="filled">
            <InputLabel style={{ color: err.selectedService ? 'red' : 'white' }}>Тип обращения</InputLabel>
            <Select
              value={selectedService}
              onChange={handleSelectChange}
              name="selectOption"
            >
              <ListSubheader>Начать сотрудничество</ListSubheader>
              <MenuItem value="Графический Дизайн">Графический Дизайн</MenuItem>
              <MenuItem value="FrontEnd разработка">FrontEnd разработка</MenuItem>
              <MenuItem value="BackEnd разработка">BackEnd разработка</MenuItem>
              <MenuItem value="Системное Администрирование">Системное Администрирование</MenuItem>
              <MenuItem value="Комплексные продукты">Комплексные продукты</MenuItem>
              <ListSubheader>Другие вопросы</ListSubheader>
              <MenuItem value="Сотрудничество">Сотрудничество</MenuItem>
              <MenuItem value="Реклама">Реклама</MenuItem>
              <MenuItem value="Вакансии">Вакансии</MenuItem>
              <MenuItem value="Другое">Другое</MenuItem>
            </Select>
          </FormControl> */}
          <FormControl className={classes.select} variant="filled"
          >
            <InputLabel style={{ color: err.selectedService ? 'red' : 'white' }}>Тип заявки</InputLabel>
            <Select
              value={selectedService}
              onChange={handleSelectChange}
              name="selectOption"
            >
             <ListSubheader>Начать сотрудничество</ListSubheader>
              <MenuItem value="Графический Дизайн">Графический Дизайн</MenuItem>
              <MenuItem value="FrontEnd разработка">FrontEnd разработка</MenuItem>
              <MenuItem value="BackEnd разработка">BackEnd разработка</MenuItem>
              <MenuItem value="Системное Администрирование">Системное Администрирование</MenuItem>
              <MenuItem value="Комплексные продукты">Комплексные продукты</MenuItem>
              <ListSubheader>Другие вопросы</ListSubheader>
              <MenuItem value="Сотрудничество">Сотрудничество</MenuItem>
              <MenuItem value="Реклама">Реклама</MenuItem>
              <MenuItem value="Вакансии">Вакансии</MenuItem>
              <MenuItem value="Другое">Другое</MenuItem>
            </Select>
          </FormControl>
          <TextField
            className={classes.email}
            type="email"
            label="Email"
            value={emailText}
            onChange={handleEmailChange}
            variant="filled"
            InputLabelProps={{
              style: { color: err.emailText ? 'red' : 'white' }, // Меняем цвет текста
            }}
          />

          <TextField
            className={classes.email}
            type="text"
            label="Ваше Имя"
            value={name}
            onChange={handleNameChange}
            variant="filled"
            InputLabelProps={{
              style: { color: err.name ? 'red' : 'white' }, // Меняем цвет текста
            }}
          />
          <FormControl className={classes.select} variant="filled"
          >
            <InputLabel style={{ color: err.selectedCell ? 'red' : 'white' }}>Тип обратной связи</InputLabel>
            <Select
              value={selectedCell}
              onChange={handleSelectChangeCall}
              name="selectOption"
            >
              <MenuItem value="Звонок">Звонок</MenuItem>
              <MenuItem value="WhatsApp">WhatsApp</MenuItem>
              <MenuItem value="Telegram">Telegram</MenuItem>
              <MenuItem value="email">email</MenuItem>
            </Select>
          </FormControl>
          {/* {(selectedCell === "Звонок" || selectedCell === "WhatsApp") && ( */}
            <TextField
              className={classes.message}
              type="text"
              name="phone"
              label="Телефон"
              value={phone}
              onChange={handlePhoneChange}
              multiline
              InputLabelProps={{
                style: { color: err.phone ? 'red' : 'white' }, // Меняем цвет текста
              }}
              variant="filled"
            />
          {/* )} */}
          {/* {selectedCell === "Telegram" && (<>  <TextField
            className={classes.message}
            type="text"
            name="phone"
            label="Телефон"
            value={phone}
            onChange={handlePhoneChange}
            multiline
            InputLabelProps={{
              style: { color: err.phone ? 'red' : 'white' }, // Меняем цвет текста
            }}
            variant="filled"
          />

            <TextField
              className={classes.message}
              type="text"
              name="phone"
              label="Tg Username"
              value={tg_name}
              onChange={handleTgChange}
              multiline
              InputLabelProps={{
                style: { color: err.tg_name ? 'red' : 'white' }, // Меняем цвет текста
              }} variant="filled"
            />
          </>
          )} */}
          <TextField
            className={classes.message}
            type="text"
            name="message"
            label="Опишите вашу задачу"
            value={messageText}
            onChange={handleMessageChange}
            placeholder="Так мы быстрее сможем подобрать нужного специалиста и начать работу"
            multiline
            rows={5}
            InputLabelProps={{
              shrink: true, // Лейбл всегда виден
              style: { color:err.messageText? 'red':'white'  }, // Цвет лейбла
            }}
            // InputProps={{
            //   style: { color: err.messageText? 'red':'white'  }, // Цвет текста внутри поля
            // }}
            variant="filled"
          />
          {/* <TextField
            className={classes.message}
            type="text"
            name="message"
            label="Опишите вашу задачу"
            value={messageText}
            onChange={handleMessageChange}
            placeholder='Так мы быстрее сможеи подобрать нужного специалиста и начать работу'
            multiline
            rows="5"
            InputLabelProps={{
              style: { color: err.messageText?'red':'white' }, // Меняем цвет текста
            }}
            variant="filled"
          /> */}




          <Button className={classes.submit} type="submit" variant="contained">
            Отправить заявку
          </Button>
        </>
        )}
        {status === 'ERROR' && <p>Ooops! There was an error.</p>}
      </form>
    </div>
  )
}

export default ContactForm
